import React from "react"
import { Header } from "../components/HeaderSection"
import { ClientsContent } from "../components/ClientsContent"
import { Footer } from "../components/Footer"
import SEO from "../components/seo"

const Clientele = () => {
  return (
    <section className="bg-body-lightest">
      <SEO
        title="Clients"
        description="Environmental Solutions is proud to have served more than 300 clients in Kerala, ranging from builders and hotels to educational institutions and hospitals."
      />
      <Header />
      <div className="w-full flex flex-col md:flex-row">
        <ClientsContent />
      </div>
      <Footer />
    </section>
  )
}

export default Clientele
