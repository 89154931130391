import React from "react"
import { Container } from "./Container"
import { Images } from "../components/Images"
import { SplitLetters } from "../components/SplitLetters"

const headerStyles =
  "font-semibold text-3xl text-primary font-sans pb-3 pt-10 text-center sm:text-4xl"

export const ClientsContent = () => (
  <section className="flex flex-col">
    <Container>
      <div className="content-wrapper">
        <h1 className={headerStyles}>
          <SplitLetters text="Industries We Serve" />
        </h1>
        <p className="sm:flex-col-reverse">
          We serve clients ranging from builders and hotels to educational
          institutions, hospitals and have our footprints in pretty much every
          industry!
        </p>
        <p className="sm:flex-col">
          Within a short period, Environmental Solutions have earned people's
          trust and a strong satisfied client base of more than 300 clients in
          Kerala. Our present turnover is about 4 Crores. Due to the
          overwhelming response for the Plants designed by us we have orders
          worth 6 Crores in hand.
        </p>
      </div>
    </Container>
    <div className="flex flex-wrap bg-white p-4 justify-center">
      <Images
        className="flex flex-wrap bg-white p-4 justify-center"
        filterByText="clients"
        imageClassName="w-32 scale_on_hover hover:shadow-lg"
      />
    </div>
  </section>
)
