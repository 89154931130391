import React, { useState } from "react"
import Img from "gatsby-image"
import CloseIcon from "../assets/close.svg"
import PrevIcon from "../assets/arrow_left.svg"
import NextIcon from "../assets/arrow_right.svg"

export const Gallery = ({ requestedImages, onClose, currentImage }) => {
  const [displayImage, setDisplayImage] = useState(currentImage)
  const ctrlIconClasses =
    "cursor-pointer z-10 flex justify-center w-full h-auto p-6 hover:bg-primary"
  return (
    <section className="bg-black-85 fixed inset-0 h-screen w-screen z-50 flex flex-col justify-center items-center text-white">
      <div className="bg-body-lightest p-2 w-80 h-80 sm:w-120 sm:h-120">
        <Img
          fluid={requestedImages[displayImage].node.childImageSharp.fluid}
          className="absolute w-full h-full"
        />
      </div>
      <div className="flex items-center text-black bg-body-lightest justify-between w-80 sm:w-120">
        <div
          className={ctrlIconClasses}
          onClick={() =>
            setDisplayImage(
              displayImage <= 0 ? requestedImages.length - 1 : displayImage - 1
            )
          }
        >
          <PrevIcon className="fill-current h-6 w-6" />
        </div>
        <div
          className={ctrlIconClasses}
          onClick={() =>
            setDisplayImage(
              displayImage >= requestedImages.length - 1 ? 0 : displayImage + 1
            )
          }
        >
          <NextIcon className="fill-current h-6 w-6" />
        </div>
        <div className={ctrlIconClasses} onClick={onClose}>
          <CloseIcon className="fill-current" />
        </div>
      </div>
    </section>
  )
}
